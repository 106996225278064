@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=IBM+Plex+Sans+Hebrew:wght@100;200;300;400;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif+Hebrew:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "danaYad";   /*Can be any text*/
  src: local("danaYad"),
    url("./fonts/DanaYadAlefAlefAlef-Normal.otf") format("truetype");
}

#page-master {
  overflow: auto;
  text-align: center;
  direction: rtl;
  display: flex;
  background-color: #FBE8CD;
  background-repeat: repeat;
  background-size: cover;
}

@keyframes moveInFromRight {
  from {

    background-color: #FBE8CD;
    transform: translateX(-100%);
  }

  to {

    background-color: #FBE8CD;
    transform: translateX(0);
  }
}

#full-page-master {
  overflow: auto;
  text-align: center;
  direction: rtl;
  display: flex;
  min-height: 100vh;
}

#product-master-new {
  width: 90%;
  margin: auto;
  box-shadow: 2px 2px 10px #a57733;
  text-align: center;
  direction: rtl;
  margin: 5%;
  margin-top: 1%;
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: hidden;
  /* border: 1px solid #a57733; */
  border-radius: 20px;
  /* height: 15vw; */
}

#product-master-new0 {
  opacity: 0;
  width: 90%;
  margin: auto;
  box-shadow: 2px 2px 10px #a57733;
  text-align: center;
  direction: rtl;
  margin: 5%;
  margin-top: 1%;
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: hidden;
  /* border: 1px solid #a57733; */
  border-radius: 20px;
  /* height: 15vw; */
}

#product-master-new-red {
  width: 90%;
  margin: auto;
  box-shadow: 2px 2px 10px red;
  text-align: center;
  direction: rtl;
  margin: 5%;
  margin-top: 1%;
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: hidden;
  /* border: 1px solid #a57733; */
  border-radius: 20px;
  /* height: 15vw; */
}

.product_button_new {
  background-color: #a57733;
  width: 100%;
  padding: 2vw;
  color: #fff;
  border-radius: 60px;
  margin-top: auto;
  border: 0px;
}

.event_button_new {
  background-color: #a57733;
  width: 100%;
  padding: 2vw;
  color: #fff;
  margin-top: auto;
  border: 0px;
}

.product_button_watch {
  background-color: #a57733;
  color: #fff;
  border: 1px solid #a57733;
  width: 20%;
}

.product_box {
  margin-left: 5%;
  margin-right: 5%;
}

.product_amount {
  color: #000;
  font-size: calc(4vmin);
  font-weight: 600;
  padding: 3vw;
}

.product_p1 {
  padding: 1vw;
}

.product_p2 {
  padding: 1vw;
}

#product-master-img {
  min-width: 28vw;
  max-width: 28vw;
  min-height: 42vw;
  max-height: 42vw;
  height: fit-content;
  text-align: center;
  direction: rtl;
  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: hidden;
  background-image: url(./image/menugreenframe.png);
  background-position: "";
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-right: 5%;
  padding-top: 12%;
  padding-left: 5%;
  padding-bottom: 5%;
  margin: 1%;
}

.rotate-1 {
  transform: rotate(-1deg)
}

.rotate-2 {
  transform: rotate(-2deg)
}

.rotate-3 {
  transform: rotate(-3deg)
}

.rotate-4 {
  transform: rotate(-4deg)
}

.rotate-5 {
  transform: rotate(-5deg)
}

.rotate-6 {
  transform: rotate(-6deg)
}

.rotate-7 {
  transform: rotate(-7deg)
}

.rotate-8 {
  transform: rotate(-8deg)
}

.rotate-10 {
  transform: rotate(-10deg)
}

.rotate1 {
  transform: rotate(1deg)
}

.rotate2 {
  transform: rotate(2deg)
}

.rotate3 {
  transform: rotate(3deg)
}

.rotate4 {
  transform: rotate(4deg)
}

.rotate5 {
  transform: rotate(5deg)
}

.rotate6 {
  transform: rotate(6deg)
}

.rotate7 {
  transform: rotate(7deg)
}

.rotate8 {
  transform: rotate(8deg)
}

.rotate9 {
  transform: rotate(9deg)
}

.rotate10 {
  transform: rotate(10deg)
}

.textrotate {
  transform: translate(20px, 85px) rotate(-30deg);
}

.logo_home_bottom {
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 10px;
  object-position: center;
  height: 17.8vw;
  width: 17.8vw;
  object-fit: cover;
}

.gcoin {
  height: 6vw;
  margin: 1vw;
  margin-bottom: -1vw;
}

.product_pic_new {
  /* padding: 20%; */
  padding-bottom: 0;
  padding-top: 0;
  /* max-height: 30vh;
  width: auto;
  align-items: center; */
  /* object-fit: cover; */
  object-position: center;
  height: 17.8vw;
  width: 17.8vw;
  border-radius: 50%;
  object-fit: cover;
}

.contact_us_pic {
  /* padding: 20%; */
  padding-bottom: 0;
  padding-top: 0;
  /* max-height: 30vh;
  width: auto;
  align-items: center; */
  /* object-fit: cover; */
  object-position: center;
  max-width: 35vw;
  object-fit: cover;

}

.home_gallery_pic {
  /* padding: 20%; */
  padding-bottom: 0;
  padding-top: 0;
  /* max-height: 30vh;
  width: auto;
  align-items: center; */
  /* object-fit: cover; */
  object-position: center;
  height: 300px;
  width: 300px;
  border-radius: 5%;
  object-fit: cover;
}

#page-wrap {
  /* padding-top: 15vw; */
  font-family: 'Noto Serif Hebrew', serif;
  direction: rtl;
  text-align: center;
  margin: 0 auto;
  /* 
  background-position: center; */
  width: 100%;
  min-height: 100vh;
  /* border: 5px solid red; */
  box-sizing: border-box;
  /* background-size: contain; */
  overflow-x: hidden;
  overflow-y: auto;

  animation: moveInFromRight 0.5s ease-in-out;
  /* animation: moveInFromRight 2s ease-in-out; */
}

.background {
  background-color: #FBE8CD;
  background-repeat: repeat;
  background-size: cover;
}

.background_cover {
  background-color: #fffaf2;
  background-image: url(./image/background.png);
  background-repeat: repeat;
  background-size: cover;
}

.background_white {
  background-color: #FBE8CD;
  background-repeat: repeat;
  background-size: cover;
}



.div_master {
  border-radius: 5vmin;
  background-color: #272614;
  color: #FBE8CD;
  margin: 2em;
  padding: 2em;
}


.brown_border {
  -webkit-text-stroke: 1px #653514;
}

.black_border {
  -webkit-text-stroke: 0.2px #000;
}

.black_border1 {
  -webkit-text-stroke: 1px #000;
}

.white_border {
  -webkit-text-stroke: 0.4px #fff;
}

.black_shadow {
  text-shadow: #653514 1px 1px;
}


.white_shadow {
  text-shadow: #fff 0 -2px;
}

.home_nicepic {
  width: 100%;
  height: 250px;

}

.pic_home {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: right;
  /* background-color: #272614; */
  color: #FBE8CD;
  min-height: 40vh;
  max-width: 100vw;
}

.pic_home_text {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: right;
  /* background-color: #272614; */
  color: #FBE8CD;
  min-height: 400px;
  padding: 3%;
  /* margin-top: -18vw; */
  border-radius: 7%;
  margin-left: 10px;
  margin-right: 10px;
}

.pic_home_text_botttom {
  position: absolute;
  bottom: 4vw;
  right: 4vw;
  /* margin-top: -18vw; */
}

.pic_home_text_botttom1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pic_home_text_botttom2 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pic_home_text_up {
  position: absolute;
  top: 4vw;
  left: 4vw;
  /* margin-top: -18vw; */
}

.nicepic1 {
  background-image: url(./image/nicepic1.jpg);
}

.nicepic2 {
  background-image: url(./image/nicepic2.jpg);
}

.nicepic3 {
  background-image: url(./image/nicepic3.jpg);
}

.home_image {
  width: 50%;
  height: auto;
  position: absolute;
  /* bottom: 0; */
}

.bottom {
  position: absolute;
  bottom: 20%;
}

.bottom0 {
  bottom: 5vw;
}

.bottom10 {
  bottom: 19vw;
}

.bottom20 {
  bottom: 24vw;
}

.home_button {
  position: relative;
}

.line_png {
  width: 100%;
  height: auto;
}

.welcome_image {
  position: absolute;
  color: #FBE8CD;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1000;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  width: 100%;
}

.width100 {
  min-width: 100%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90% !important;
}

.width75 {
  width: 75%;
}

.width70 {
  width: 70%;
}

.width50 {
  width: 50%;
}

.max_width50 {
  max-width: 50%;
}

.width30 {
  width: 30%;
}

.width30vw {
  width: 30vw;
}

.width20 {
  width: 20%;
}

.margin-25 {
  margin-right: -25px
}

.intro_header {
  outline-width: 100%;
  font-size: x-large;
  padding-top: 50px;
  padding-bottom: 50px;

}

.intro_header {
  background-color: black;
  width: 100%;
  font-size: x-large;
  padding-top: 50px;
  padding-bottom: 50px;

}

.text_rtl {
  text-align: right;
  direction: rtl;
}

.text_center {
  font-family: 'Noto Serif Hebrew', serif;
  color: #A57735;
  text-align: center;
  direction: rtl;
  /* -webkit-text-stroke: 0.6px #653514; */
}

.text_sub {
  font-family: 'Noto Serif Hebrew', sans-serif !important;
  /* -webkit-text-stroke: 0.6px #653514; */
}

.text_title {
  font-family: 'Noto Serif Hebrew', serif !important;
}

.css-319lph-ValueContainer {
  overflow: unset !important
}

.text_dark {
  font-family: 'Noto Serif Hebrew', serif;
  color: #272614;
}

.atcb {
  font-family: 'Noto Serif Hebrew', serif;
  color: #653514 !important;
  box-shadow: 1px #653514 !important;
}

.text_brown {
  font-family: 'Noto Serif Hebrew', serif;
  color: #653514 !important;
}

.text_title_img {
  font-family: 'Noto Serif Hebrew', serif;
  color: #FBE8CD;
  text-align: center;
  direction: rtl;
  /* -webkit-text-stroke: 0.6px #653514; */
  background-image: url(./image//title_wallpaper.png);
  /* background-position: ""; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 2vw;
  padding-bottom: 3vw;
  padding-left: 15vw;
  padding-right: 15vw;
  margin-right: -40px;
  margin-left: -40px;
}

.alice-carousel__dots-item.__active {

  background-color: #653514 !important;
}

.alice-carousel__dots-item {

  background-color: #a57733 !important;

}




/* .alice-carousel__stage{
  background: linear-gradient(#f005, #f005), linear-gradient(#0f05, #0f05);

}
.alice-carousel__stage-item{
  background: linear-gradient(#f005, #f005), linear-gradient(#0f05, #0f05);

} */


.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  /* for demo purpose  */
}

.stack-top {
  z-index: 9;
  margin: 20px;
  /* for demo purpose  */
}

/* .alice-carousel:before {
  content:"";
  display:block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.25);
} */


.text_welcome {
  font-family: 'Noto Serif Hebrew', serif;
  color: #A57735;
  text-align: center;
  letter-spacing: 0.4em;
  direction: rtl;
  -webkit-text-stroke: 0.6px #653514;
  padding-left: 10px;
}

.text_g {
  color: #FBE8CD;
  text-align: center;
  margin-top: -5px;
  direction: rtl;
  z-index: 1000;
}

.text_red {
  color: red;
  text-align: center;
  direction: rtl;
}

.text_green {
  color: green;
  text-align: center;
  direction: rtl;
}

/* paddingVertical: 15,
    paddingHorizontal: 10, */

/* .button_icon {
  flex-direction: "row";
  justify-content: space-evenly;
  align-items: "center";
} */

.text_eng {
  font-family: 'Quicksand', sans-serif !important;
  direction: ltr !important;
}

.text_black {
  font-family: 'Noto Serif Hebrew', serif;
  color: rgb(0, 0, 0);
}

.text_transparent {
  color: transparent;
}

.line_middle {
  text-decoration-line: line-through;
}

.text_white {
  font-family: 'Noto Serif Hebrew', serif;
  color: #FDEACE;
}

.text_black_rtl {
  font-family: 'Noto Serif Hebrew', serif;
  color: rgb(0, 0, 0);
  text-align: right;
}

.link_primary {
  color: #A57735;
}

.link_site {
  color: #653514;
  font-size: 5vw;
  margin: 1vw;
}

.text {
  color: #A57735;
}

.text0 {
  font-size: 1.5vw
}

.text1 {
  font-size: 3vw;
}

.text1_2 {
  font-size: 3.5vw !important;
}

.text1_3 {
  font-size: 4vw !important;
}

.text1_5 {
  font-size: 4.5vw !important;
}

.text1_9 {
  font-size: 5.0vw !important;
}

.text2 {

  font-size: 6vw !important;
}

.text2_5 {

  font-size: 7.5vw;
}

.text3 {

  font-size: 9vw;
}

.text3_5 {
  font-size: 10.5vw;
}

.text4 {
  font-size: 13em;
}

.text4_5 {
  font-size: 14.5vw;
}

.w100 {
  font-weight: 100;
}

.w200 {
  font-weight: 200;
}

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.p1 {
  padding: 1vw;
}

.p2 {
  padding: 2vw;
}

.p3 {
  padding: 3vw;
}

.p3313 {
  padding: 3vw 3vw 1vw 3vw;
}

.p4 {
  padding: 4vw;
}

.p5 {
  padding: 5vw;
}

.p6 {
  padding: 6vw;
}

.p7 {
  padding: 7vw;
}

.p8 {
  padding: 8vw;
}

.underline {
  text-decoration: underline;
}


.p10 {
  padding: 10vw;
}

.p1010 {
  padding-left: 10vw;
  padding-right: 10vw;
}

.height30 {
  max-height: 30vw;
}

.height50 {
  min-height: 80vw;
}

.height30x {
  min-height: 30vw;
}

.width40x {
  min-width: 35vw;
  max-width: 35vw;
}

.split_div_menu {
  display: flex;
  flex-wrap: wrap;
}

.split_div {
  display: flex;
}

.row_div {
  display: flex;
  flex-direction: row;
}

.row_div_space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.date_box {
  width: 30vw;
  height: 20vw;
  border-radius: 2vw;
  background-image: url(./image/luh_note.png);
  background-position: "";
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-right: 5%;
  padding-top: 12%;
  padding-left: 5%;
  padding-bottom: 5%;
  margin: 1%;
}

.digital-clock {
  padding: 4px;
  border: 1px solid red;
  font-weight: bold;
  color: red;
  text-align: center;
}

.green-digital-clock {
  padding: 4px;
  border: 1px solid green;
  font-weight: bold;
  color: green;
  text-align: center;
}


.grid-container {
  background-image: url(./image/luh_shayam.png);
  background-position: "";
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-right: 5%;
  padding-top: 17%;
  padding-left: 5%;
  padding-bottom: 5%;
  /* display: flex; */
  /* justify-content: space-evenly; */
  /* justify-items: center; */
  margin: 2%;
  height: 110vw;
  position: relative;
  z-index: 5 !important;
}

.grid-item {
  font-family: 'Caveat', cursive;
  text-align: center;
  background-image: url(./image/luh_note.png);
  background-position: "";
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-right: 5%;
  padding-top: 15%;
  padding-left: 10%;
  padding-bottom: 15%;
  position: absolute;
  /* top:15%;
  top:50%;
  left: 0%;
  left: 50%; */
  width: 40vw;
  height: 50vw;
  
  font-size: 9vw;
}

.text_event {
  /* font-family: 'Caveat', cursive !important; */
  font-family: "danaYad" !important;
  font-size: 6vw;
  margin-top: -3vw;
  
}


.leftttt {
  align-items: flex-start !important;
}

.left_div,
.right_div {
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.right_div_70 {
  flex: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.space_div {
  flex: 1%;
}


.right_div_3 {
  flex: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.right_div_3_right {
  flex: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: right;
}

.right_div2_3 {
  flex: 66%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#welcome_home {
  background-color: #FDEACE;
}

#welcome_title {
  color: #3D3F1B;
  font-size: calc(13vmin);
  font-weight: 500;
}

#welcome_title_small {
  color: #3D3F1B;
  font-size: calc(6vmin);
  font-weight: 400;
}

.welcome_button {
  background-color: #FBE8CD;
  color: #653514;
  border-radius: calc(5vmin);
  border: 0px solid #653514;
  position: relative;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: calc(6vmin);
}

.menu-bars-heart {
  position: absolute;
  font-size: 1.7rem;
  background: #fff;
  padding: 7px;
  padding-bottom: 0px;
  border-radius: 10px;
  border: 1px solid red;
  left: 3vw;
}

.storage_box {
  background-color: #FBE8CD;
  color: #653514;
  border-radius: calc(5vmin);
  border: 1.5px solid #653514;
  width: 32%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: calc(6vmin);
  overflow-x: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: auto;
}

.overflow {
  overflow-y: scroll !important;
  overflow-x: scroll !important;
  width: 100%;
}

.storage_layout {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: auto;
}

.storage_button {
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  border: none;
  margin-bottom: 10px;
  color: white;
  background: #dc3545;
  padding: 0px;
  text-align: center;
  font-size: calc(3vmin);
}

.close_button {
  position: fixed;
  color: #a57733;
  padding-top: 10px;
  background-color: transparent;
  border: 0px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  left: 18vw;
  top: 18vw;

}

.cancel_button {
  background-color: transparent !important;
  border: 0.1pc solid #FBE8CD !important;
  color: #a57733 !important;
}

.cancel_button1 {
  background-color: transparent !important;
  border: 0.1pc solid #a57733 !important;
  color: #a57733 !important;
}

.primary_button {
  font-family: 'Noto Serif Hebrew', serif !important;
  background-color: #a57733 !important;
  border: 0.1pc solid #FBE8CD !important;
  color: #FBE8CD !important;
}

.primary_button1 {
  background-color: #a57733 !important;
  border: 0.1pc solid #a57733 !important;
  color: #FBE8CD !important;
}

.brown_button {
  background-color: #653514 !important;
  border: 0.1pc solid #FBE8CD !important;
  color: #FBE8CD !important;
}

.button_pic {
  position: absolute;
  top: -10vw;
}


#cart-button-new {
  display: flex;
  flex-direction: row;
  padding: calc(3vmin);
  box-shadow: 2px 2px 12px #000;
  background-color: #fffaf2;
  color: #a57733;
  border-radius: calc(2vmin);
  position: fixed;
  padding-top: 10px;
  width: fit-content;
  font-size: calc(10vmin);
  padding-bottom: 4px;
  left: 20px;
  bottom: 25px;
  /* z-index: 3; */
}

.button_icon {
  font-size: calc(4vmin);
}

#right_welcome {
  padding-top: 15vw;
  padding-left: 17vw;
  padding-bottom: 0vw;
  padding-right: 3vw;
  flex-grow: 0;
}

.welcome_button_google {
  background-color: #A57735;
  color: #FBE8CD;
  border-radius: calc(5vmin);
  position: relative;
  width: 85%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: calc(6vmin);
}


.sidebar_logo {
  /* padding: 60px; */
  max-width: 80px;
}

.message_modal {
  margin: 8vw;
}

























* {
  box-sizing: border-box;
}

#content {
  padding: 0.5rem;
  display: flex;
  color: black;
}

#left,
#right {
  padding: 0.5rem;
  flex-grow: 1;
}




@media (min-width:400px) {
  .div_master {
    max-width: 400px;
    margin: 2em auto 2em auto;
  }


}






.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.bg-purple-100 {
  background-color: #faf5ff;
}

.shepherd-element .shepherd-content footer .shepherd-buttons li .shepherd-button:hover {
  background: #0a1632;
}

.shepherd-element .shepherd-content footer .shepherd-buttons li .shepherd-button.shepherd-button-secondary {
  background: #e5e5e5;
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-element .shepherd-content footer .shepherd-buttons li .shepherd-button.shepherd-button-secondary:hover {
  background: #cbcbcb;
  color: rgba(0, 0, 0, 0.75);
}