.navbar {
    position: fixed;
    justify-content: start;
    z-index: 10;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 1.7rem;
    background: #fff;
    padding: 7px;
    padding-bottom: 0px;
    align-items: center;
    align-content: center;
    border-radius: 10px;
    border: 1px solid #a57733;
}
.menu-bars-back {
    position: absolute;
    /* width: 10vw; */
    padding-bottom: 0px;
    right: 3vw;
    top:22vw;
    z-index: 9;
}

.menu-bars-back-img {
    border-radius: 10px;
    border: 1px solid #a57733;
}

.cart_amount_bar {
    position: absolute;
    font-size: 3.5vw;
    background: #fbe8cd;
    border-radius: 50%;
    border: 1px solid #a57733;
    color: #a57733;
    top: 1vw;
    left: 8vw;
    color: #a57735 !important;
    z-index: 10;
    width: 6vw;
    height: 6vw;
    text-align: center;
    transition: 700ms;
}

.menu-bar-open {
    position: absolute;
    font-size: 1.7rem;
    right: 3vw;
    top: 4vw;
    color: #a57735 !important;
}

.menu-bar-left {
    position: absolute;
    font-size: 1.7rem;
    top: 4vw;
    left: 3vw;
    color: #a57735 !important;
}

.app_bar {
    /* background-image: url(../../image/darkgreenwood.png); */
    background-color: #272614;
    background-repeat: repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 16vw;
}

.menu-icon {
    margin-left: 1rem;
    font-size: 2rem;
    background: none;
    padding: 0.05em;
}


.nav-menu {
    z-index: 1100;
    box-shadow: -20px 0px 30px #272614;
    background-color: #fff;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    direction: rtl;
    right: -100%;
    transition: 850ms;
}


.nav-menu.active {
    color: #a57733;
    right: 0;
    line-height: 20px;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    transition: 550ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
}

.nav-text-a {
    font-size: 1rem;
}

.App-logo {
    display: flex;
    align-items: center;
    list-style: none;
}

.nav-text a {
    text-decoration: none;
    color: #a57733 !important;
    font-size: 18px;
    width: 95%;
    margin: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.paddingbarlogo {
    height: 14vw;
    padding-bottom: 1vw;
    padding-top: 2.5vw;
}

.menu_button {
    background-color: #a57735;
    color: #fbe8cd;
    border-radius: calc(5vmin);
    border:  0px solid #653514;
    position: relative;
    padding: 9vw;
    padding-top: 3vw;
    padding-bottom: 3vw;
  }

.nav-text a:hover,
.nav-text a:hover>.menu-icon {
    background-color: #a57735;
    font-size: 1.5rem;
    color: #fff !important;
    transition: 0.5s;
}

.nav-menu-items {
    width: 100%;
}

.nav-space {
    min-width: 20px;
    font-size: calc(10px + 1vmin);
    width: 100%;
    color: #a57733;
    border-bottom: 1px solid #a57733;
}

.navbar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
}

/* span {
    margin-left: 16px;
    padding: 0.25em;
} */