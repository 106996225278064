@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "react-alice-carousel/lib/alice-carousel.css";

html,


body {
  margin: 0;

}

h1 {
  text-decoration: underline;
  font-size: calc(6vmin);

}

h9 {
  font-size: calc(6vmin);
  font-weight: 600;
  color: #a57733;
  display: flex;
  justify-content: space-evenly;

}

h2 {
  margin: 0;
}

table {

  border-collapse: collapse;
  border: 1px solid;
  width: 100%;
}

th {
  color: #a57733;
  border: 1px solid;
  font-weight: 800;
}

tr {
  margin: 10px;
  color: #000;
  border: 1px solid;
}

td {
  color: #000;
  font-weight: 400;
}

#App {
  font-family: sans-serif;
  height: 100vh;
}

#cart-button {
  display: flex;
  flex-direction: row;
  padding: calc(3vmin);
  box-shadow: 2px 2px 12px #000;
  background-color: #fffaf2;
  color: #a57733;
  border-radius: calc(2vmin);
  position: fixed;
  padding-top: 10px;
  width: fit-content;
  font-size: calc(10vmin);
  padding-bottom: 4px;
  left: 20px;
  bottom: 25px;
  /* z-index: 3; */
}

#message-layout {
  padding: calc(3vmin);
  box-shadow: 2px 2px 12px #000;
  background-color: #fffaf2;
  color: #a57733;
  border-radius: calc(2vmin);
  padding-top: 10px;
  width: 100%;
  font-size: calc(10vmin);
  padding-bottom: 4px;
  left: 20px;
  bottom: 25px;
  margin-bottom: 20px;
  display: flex;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#cart-icon {
  border-radius: 20px;
  border: 2px solid#a57733;
  background-color: #fff;
  color: #a57733;
  width: min-content;
  font-weight: 800;
  padding-left: calc(1vmin);
  padding-right: calc(1vmin);
  padding-top: calc(0.3vmin);
  padding-bottom: calc(0.3vmin);
  margin-right: 10px;
  position: relative;
  margin-bottom: -25px;
  font-size: calc(2.2vmin);


}

#cart-button1 {
  font-weight: 800;
  padding-left: calc(2vmin);
  font-size: calc(4vmin);
}

#message-button1 {
  font-weight: 800;
  padding-left: calc(2vmin);
  font-size: calc(4vmin);
}

#contact-wrap {

  padding-top: 13vw;
  height: 100vh;
  direction: rtl;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}


#personal-wrap-m {
  direction: rtl;
  text-align: center;
  margin: 0 auto;
}

.menu-layout {
  background-color: #fffaf2;
  background-image: url(./image/background.png);
  background-repeat: repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  /* background-color: rgba(0, 0, 0, 0.8); */
  overflow: auto;
  z-index: 5;
}

.transition7 {
  transition: 700ms;
}

.no-active {
  position: fixed;
  width: 0%;
  height: 1000%;
  z-index: -1;
  background-color: "";
  left: 200%;
}

.menu-layout-color {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgb(0, 86, 2);
  overflow: auto;
}

.padding10 {
  padding: 10px
}

.padding1 {
  padding: 1vw
}

#page95 {
  height: 100vh;
  direction: rtl;
  text-align: center;
  margin: 0 auto;
  width: 95%;
}

#page-wrap-m {
  height: 100vh;
  direction: rtl;
  margin: 0 auto;
  overflow: visible;
  width: 2000px;

}

#personal-wrap {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid#a57733;
}

#message-wrap {

  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid#a57733;
}


#p-master {
  overflow: auto;
  text-align: center;
  direction: rtl;
  display: flex;
}

#loading {
  color: #a57733;
  left: 0;
}

#page-menu {
  display: flex;
  overflow-x: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: hidden;
}

#page-products {
  display: flex;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: hidden;
}

.top_padding {
  padding-top: 23vw;
}

.top_padding_home {
  padding-top: 16vw;
}

.top_padding_arrow {
  position: absolute;
  margin-top: 5vw;
  left: 7vw;
}

.infront1 {
  z-index: 1 !important;
}

.infront2 {
  z-index: 2 !important;
}

.inback {
  z-index: -1;
}

#page-inventory {
  display: flex;
  flex-direction: row;
  /* overflow-x: scroll; */
  flex-wrap: nowrap;
  justify-content: space-evenly;
  overflow: auto;
  padding: 5;
  border: 1px solid #a57733;
}

#page-event {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  direction: rtl;
}

#product-title {
  border-bottom: 1px solid#a57733;
  width: 100%;
  color: #a57733;
  text-align: center;
  direction: rtl;
  font-weight: 700;
  font-size: calc(8vmin);
}

#product-des {
  padding: calc(5vmin);
  text-align: center;
  direction: rtl;
  font-weight: 700;
  font-size: calc(4vmin);
}

#title {
  width: 100%;
  color: #a57733;
  text-align: center;
  direction: rtl;
  font-weight: 700;
  font-size: calc(20px + 1vmin);
}

#title1 {
  width: 100%;
  color: #a57733;
  text-align: center;
  direction: rtl;
  font-weight: 700;
  font-size: calc(15px + 1vmin);
}

#title1red {
  width: 100%;
  color: red;
  text-align: center;
  direction: rtl;
  font-weight: 700;
  font-size: calc(15px + 1vmin);
}

#title2 {
  width: 100%;
  color: #a57733;
  text-align: center;
  direction: rtl;
  font-weight: 700;
  font-size: calc(10px + 1vmin);
}

#title3 {
  width: 100%;
  color: green;
  text-align: center;
  direction: rtl;
  font-weight: 700;
  font-size: calc(15px + 1vmin);
}

#tip-title {
  border: 3px solid #653514;
  font-size: large;
  border-radius: 5vmin;
  width: fit-content;
  max-width: 75%;
  padding: 10px;
  color: #653514;
  text-align: center;
  direction: rtl;
  font-weight: 700;
  font-size: calc(20px + 1vmin);
}

#payment-title {
  font-weight: 400;
  color: #a57733;
  background-color: #fffaf2;
  box-shadow: 2px 2px 10px #a57733;
  border-radius: 1vmin;
  width: 95%;
  text-align: center;
  direction: rtl;
  font-size: calc(5px + 3vmin);
  padding: 10px;
  margin: 10px;
}


#paybox-title {
  color: #22a7f0;
  background-color: #fffaf2;
  box-shadow: 2px 2px 10px #22a7f0;
  border-radius: 1vmin;
  width: 95%;
  padding: 10px;
  text-align: center;
  direction: rtl;
}

#paybox-title-small {
  color: #22a7f0;
  background-color: #fffaf2;
  box-shadow: 2px 2px 10px #22a7f0;
  border-radius: 1vmin;
  text-align: center;
  margin: 3vw;
  max-width: 38vw;
  height: 30vw
}

.red {
  color: red !important;
  box-shadow: 2px 2px 10px red !important;

}

.green {
  color: green !important;
  box-shadow: 2px 2px 10px green !important;

}

#menu-title {
  font-size: larger;
  width: 100%;
  color: #a57733;
  text-align: right;
  direction: rtl;
  font-weight: 700;
  font-size: calc(20px + 1vmin);
}

#menu-title1 {
  font-size: larger;
  border-bottom: 1px solid#a57733;
  width: 100%;
  color: #a57733;
  text-align: right;
  direction: rtl;
  font-weight: 700;
  font-size: calc(20px + 1vmin);
}

#product-title-1 {
  font-size: large;
  text-align: center;
  direction: rtl;
  font-weight: 400;
  margin-top: 20px;
  font-size: calc(15px + 1vmin);
}

#product-sum {
  background-color: #fffaf2;
  text-align: center;
  direction: rtl;
  font-weight: 600;
  margin-top: 20px;
  font-size: calc(6vmin);
  border-bottom: 1px solid#a57733;
  border-top: 1px solid#a57733;
}

#product-master {
  min-width: 30vw;
  max-width: 35vw;
  margin: auto;
  background: #fffaf2;
  box-shadow: 2px 2px 10px #a57733;
  text-align: center;
  direction: rtl;
  margin: 10px;
  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: hidden;
  padding-top: 2%;
}

.event_button {
  background-color: #FCA902;
  min-width: 20px;
  max-width: fit-content;
  min-height: 30px;
  font-size: calc(10px + 1vmin);
  width: 100%;
  color: #a57733;
  border: 1px solid #FCA902;
  margin-top: auto;

}

#event-master {
  min-width: 90vw;
  max-width: 95vw;
  margin: auto;
  overflow-x: hidden;
  width: fit-content;
  background: #fffaf2;
  box-shadow: 2px 2px 10px #a57733;
  text-align: center;
  direction: rtl;
  margin: 10px;
  border-radius: 4vw;
}

#event-small-master {
  min-width: 90vw;
  max-width: 95vw;
  border-radius: 2vw;
  background-image: url(./image/luh_shayam.png);
  background-position: "";
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-right: 5%;
  padding-top: 10%;
  padding-left: 5%;
  padding-bottom: 5%;
  margin: 1%;
  overflow-x: scroll;
  display: grid;
  gap: 5vw;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row: 1;
  grid-template-columns: auto, auto, auto;
  align-content: space-evenly;
  justify-items: center;
}

.space {
  text-align: center !important;
  justify-content: space-around;
  align-content: space-evenly;
}

.row_display {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.event-ticket-view {
  min-width: 90%;
  box-shadow: 2px 2px 10px #a57733;
  text-align: center;
  direction: rtl;
  margin: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 2vw;
}

.event-ticket-view[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}


.swipe {
  position: absolute;
  animation: swipe-keyframes 1s;
}

@keyframes swipe-keyframes {
  from {
    top: 0px;
    left: 0px;
  }

  to {
    top: 0;
    left: 0;
  }
}

.swipe-fade {
  position: absolute;
  animation: swipe-fade-keyframes 1s;
}

@keyframes swipe-fade-keyframes {
  from {
    height: 100px;
  }

  to {
    width: 10%;
    height: 5%;
    top: 10px;
    left: -20px;
  }
}

.swipe-right {
  position: absolute;
  animation: swipe-right-keyframes 1s;
}

@keyframes swipe-right-keyframes {
  to {
    left: -100vw;
  }
}

.fade {
  animation: fade-in-keyframes 1s;
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;

  }

  to {
    opacity: 1
  }
}

.fade_in {
  animation: fade-keyframes 3s;
}

@keyframes fade-keyframes {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.fade_out {
  animation: fade-out-keyframes 1s;
}

@keyframes fade-out-keyframes {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}


.icons {
  padding: 10px;
  font-size: 30px;
}

.button_sp {
  min-width: 100px;
  min-height: 30px;
  /* font-size: calc(4vmin); */
  font-weight: 700;
  color: #a57733;
  border: 2px solid#a57733;
  padding: 10px;
  right: 0;
  margin-right: 25px;
  margin-left: 25px;
}

.clear_button {
  background-color: transparent;
  border: 0px;
}

.clear_button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.header-logo {
  padding-top: 25px;
  width: 90%;
  max-width: 500px;
}

.header-logo-1 {
  width: 70%;
  max-width: 400px;
}

.header-logo-2 {
  width: 70%;
  max-width: 150px;
}

.header-logo-3 {
  width: 70%;
  max-width: 2000px;
}

.nounderline {
  text-decoration: underline double transparent;
}

.button_lucky {
  /* background-color: #fffaf2; */
  position: sticky;
  bottom: 0;
  min-width: 20px;
  min-height: 30px;
  font-size: calc(10px + 1vmin);
  font-weight: 700;
  /* color: #a57733; */
  /* border: 2px solid#a57733; */
  border-radius: 10px;
  /* z-index: 100; */
}

.button_cart2 {
  background-color: #fffaf2;
  position: sticky;
  right: 90%;
  min-width: 20px;
  min-height: 30px;
  font-size: calc(10px + 1vmin);
  font-weight: 700;
  color: #a57733;
  border: 2px solid#a57733;
  border-radius: 10px;
  padding: 10px;
  margin-left: 20px;
}

.button_new_account {
  background-color: #FCA902;
  min-width: 20px;
  min-height: 30px;
  font-size: calc(20px + 1vmin);
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 700;
  color: #a57733;
  border: 1px solid #FCA902;

}

.product_button {
  background-color: #FCA902;
  min-width: 20px;
  min-height: 30px;
  font-size: calc(10px + 1vmin);
  width: 100%;
  color: #a57733;
  border: 1px solid #FCA902;
  margin-top: auto;
}

.product_button_only {
  position: relative;
  bottom: 0;
  background-color: #FCA902;
  font-size: calc(10px + 2vmin);
  width: 100%;
  color: #a57733;
  border: 1px solid #FCA902;
  margin-top: auto;
}

.message_button {
  background-color: #FCA902;
  min-width: 20px;
  min-height: 30px;
  font-size: calc(10px + 1vmin);
  color: #a57733;
  border: 1px solid #FCA902;
  margin-top: auto;
}

.message_master {
  min-width: 150px;
  max-width: 40vw;
  margin: auto;
  overflow: hidden;
  width: 100%;
  background: #fffaf2;
  text-align: center;
  direction: rtl;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.product_button_multi {
  background-color: #FCA902;
  min-width: 20px;
  min-height: 30px;
  font-size: calc(10px + 1vmin);
  width: 100%;
  color: #a57733;
  border: 0px solid #a57733;
  border-top: 1px solid #a57733;


}

.product_button_view {
  background-color: #fffaf2;
  box-shadow: 0px 0px 12px #a57733;
  min-width: 20px;
  min-height: 30px;
  font-weight: 700;
  margin: 20px;
  padding: 20px;
  font-size: calc(5vmin);
  color: #a57733;
  border: 2px solid #a57733;
  border-radius: 20px;
}

.product_button_view_new {
  /* background-color: #fffaf2; */
  margin: 5%;
  font-weight: 700;
  background-color: #a57733;
  border: 2px solid #FDEACE;
  border-radius: 20px;
}

.product_like {
  position: absolute;
  left: 0;
  top: 14vw;
  padding: 20px;
  font-size: calc(10vmin);
  color: red;
}

.product_price {
  font-size: medium;
  font-weight: 500;
}

.product_email {
  direction: ltr;
  font-size: large;
  font-weight: 500;
}

.product_date {
  direction: ltr;
  font-size: small;
  font-weight: 500;
}

.product_name {
  max-lines: 0;
  padding: 5px;
  font-size: larger;
  font-weight: 600;
}

.product_des {
  font-size: large;
  font-weight: 200;
}

.product_pic {
  /* max-height: 30vh;
  width: auto;
  align-items: center; */
  object-fit: cover;
  object-position: center;
  height: 25vw;
  width: 20vw;
}

.coupon_pic {
  /* max-height: 30vh;
  width: auto;
  align-items: center; */
  object-fit: cover;
  object-position: center;
  height: 30vw;
  width: 30vw;
}

.product_pic_out {
  /* max-height: 30vh;
  width: auto;
  align-items: center; */
  object-fit: cover;
  object-position: center;
  height: 25vw;
  width: 20vw;
  opacity: 40%;
}

.width40 {
  width: 40vw;
}

.event_pic {
  align-items: center;

  object-fit: cover;
  object-position: center;
  /* height: 25vw; */
  width: 40vw;
}

.product_image {
  max-height: 200px;
  width: 100%;
  align-items: center;
}

.product_image_view {
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: center;
  width: 100%;
  opacity: 0.5;
  min-height: 40vh;
}

.product_image_view_new {
  top: 0;
  right: 0;
  align-items: center;
  width: 100%;
  min-height: 40vh;
  max-height: 50vh;
}

.z_index_5 {
  z-index: -5;
}

.z_index_1 {
  z-index: -1;
}

.z_index0 {
  z-index: 0;
}

.z_index4 {
  z-index: 4;
}

.opacity05 {
  opacity: 0.5;

}

.opacity1 {
  opacity: 1 !important;

}

@media (min-width:400px) {
  .product_image_view {
    width: 30%;
    height: 100%;
  }
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.sidebar {
  max-width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.hambclicker {
  content: '';
  height: 48px;
  width: 55px;
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
  z-index: 9;
}

#hambmenu span:nth-child(1) {
  top: 0px;
}

#hambmenu span:nth-child(2),
#hambmenu span:nth-child(3) {
  top: 10px;
}

#hambmenu span:nth-child(4) {
  top: 20px;
}

#hambmenu.isopen span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
  opacity: 0;
}

#hambmenu.isopen span:nth-child(2) {
  transform: rotate(45deg);
}

#hambmenu.isopen span:nth-child(3) {
  transform: rotate(-45deg);
}

#hambmenu.isopen span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
  opacity: 0;
}

.Corency {
  font-family: sans-serif;
  text-align: center;
  background: #eee;
}