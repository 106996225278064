.form {
  text-align: center;
  border: 2px solid #a57733;
  background-color: rgb(248, 228, 185);
  padding: 30px;
  overflow: hidden;
}


.App-header {

  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.center {
  align-items: center;
}

.button {
  background-color: #a57733;
  /* min-width: 120px;
  min-height: 30px; */
  font-size: calc(10px + 1vmin);
  color: rgb(243, 235, 235);
  top: 40px;
  border: 2px solid rgb(248, 228, 185);
  border-radius: 10px
}

.button_b {
  background-color: black;
  min-width: 80px;
  min-height: 30px;
  font-size: calc(10px + 1vmin);
  color: rgb(243, 235, 235);
  top: 40px;
  border: 2px solid rgb(248, 228, 185);
  border-radius: 10px
}

.count_icon {
  color: #a57733;
  font-size: calc(6vmin);
  font-weight: 800;
}

.count_icon_new {
  color: #000;
  font-size: 5vw;
  font-weight: 800;
}

.icon {
  margin-right: 10px;
  font-size: calc(7vmin);
  font-weight: 800;
  margin-top: 10px;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Heebo", "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  font-size: 1rem;
  font-weight: 1.5;
  line-height: 1.5;
  color: #292b2c;
  background-color: #fff;

}

.center {

  margin: 0 auto;
  padding: 0 1em;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
  margin-bottom: 20px;
}

.__container {
  text-align: center;
  min-width: 90%;
  margin: 0 auto;
  border: 2px solid #272614;
  padding: 25px 40px;
}

.__logo {
  margin: -90px auto 30px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0;
}

.__datetextBox {
  direction: rtl;
  padding: 10px;
  width: 100%;
  height: fit-content;
  font-size: 18px;
  margin-bottom: 10px;
  color: #a57733;
}

.__textBox {
  direction: rtl;
  padding: 10px;
  width: 100%;
  height: fit-content;
  font-size: 18px;
  margin-bottom: 10px;
  color: #653514;
  background-color: transparent !important;
  border: 2px solid #653514;
  border-radius: 20px;
  outline-color: transparent;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #653514;
  opacity: 0.6;
  /* Firefox */
}

.__textBoxTitle {
  direction: rtl;
  padding: 10px;
  width: 100%;
  height: fit-content;
  font-size: 18px;
  margin-bottom: 10px;
  color: #653514;
  background-color: transparent !important;
  border: 2px solid transparent;
  border-bottom: 2px solid #653514;
  /* border-radius: 20px; */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #653514;
  opacity: 0.6;
  /* Firefox */
}


.__textBoxamount {
  direction: rtl;
  padding: 10px;
  height: fit-content;
  font-size: 18px;
  width: 50%;
  margin-bottom: 10px;
  color: #a57733;
}


.__textBoxSmall {
  direction: rtl;
  padding: 10px;
  width: 50%;
  height: fit-content;
  font-size: 18px;
  margin-bottom: 10px;
  color: #a57733;
}

.__textBoxNewProduct {
  direction: rtl;
  width: fit-content;
  height: fit-content;
  font-size: 18px;
  margin-bottom: 10px;
  background-color: transparent !important;
  border: 0px;
  margin: 5vw;
  margin-right: 3vw;
  margin-left: 3vw
}

.__textBoxProductChoses {
  direction: rtl;
  width: fit-content;
  height: fit-content;
  font-size: 18px;
  margin-bottom: 10px;
  color: #a57733;
  background-color: transparent;
  border: 0px;
  margin: 5vw;
  margin-right: 3vw;
  margin-left: 3vw;
  border: 1px solid #a57733;
  border-radius: 10px;
  padding: 3vw;
}

.__textBoxProductChosesClick {
  direction: rtl;
  width: fit-content;
  height: fit-content;
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
  background-color: #a57733;
  border: 0px;
  margin: 5vw;
  margin-right: 3vw;
  margin-left: 3vw;
  border: 1px solid #a57733;
  border-radius: 10px;
  padding: 3vw;
}

.__textBoxDetails {
  direction: rtl;
  width: fit-content;
  height: fit-content;
  margin-bottom: 10px;
  background-color: #fff;
  border: 0px;
  margin: 5vw;
  margin-right: 3vw;
  margin-left: 3vw;
  width: 50vw;
}

.__textBoxNatural {
  direction: rtl;
  padding: 10px;
  height: fit-content;
  font-size: 18px;
  margin-bottom: 10px;
  color: #a57733;
}

.__textBoxMessage {
  direction: rtl;
  padding: 10px;
  width: 100%;
  text-align: right;
  font-size: 18px;
  margin-bottom: 10px;
  color: #a57733;
  display: inline-block;
  height: 100px;
}

.__text {
  direction: rtl;
  padding: 10px;
  height: fit-content;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #653514;
}

.__num {
  direction: rtl;
  padding: 10px;
  width: 100px;
  height: fit-content;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #a57733;
}

.__textBoxEmail {
  direction: ltr;
  padding: 10px;
  width: 100%;
  height: fit-content;
  font-size: 18px;
  margin-bottom: 10px;
  color: #653514;
  background-color: transparent !important;
  border: 2px solid #653514;
  border-radius: 20px;
}

.__btn {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  border-radius: 5px;
  background-color: #a57733;
  ;
}

.__btn__google {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: #ca1717;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ca1717;

  ;
}

.center div {
  margin-top: 7px;
  direction: rtl;
}